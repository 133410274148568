const fetch = require("node-fetch")
//const fs = require("fs")
//const path = require("path")
//const yauzl = require("yauzl")

const local =
  typeof process.env.GATSBY_ACTIVE_ENV === "undefined" ||
  process.env.GATSBY_ACTIVE_ENV === "development"
const apihost = process.env.GATSBY_API_HOST || ""

const endpoint = process.env.GATSBY_PAGE_API_URL
const endpointSearch = process.env.GATSBY_SEARCH_API_URL
const endpointOffer = process.env.GATSBY_OFFER_API_URL
const endpointContact = process.env.GATSBY_CONTACT_API_URL
const endpointIndexPages = process.env.GATSBY_INDEX_PAGES
const endpointBuildPages = process.env.GATSBY_BUILD_PAGES

async function getPagesList() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return await fetchRetry(
    `${apihost}${endpointBuildPages}/list`,
    requestOptions,
    3
  ).then(handleResponse)
}

async function getPageContents(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }
  return await fetchRetry(
    `${apihost}${endpointBuildPages}/${id}/contents`,
    requestOptions,
    3
  ).then(handleResponse)
}

async function getAllPagesWithData(absolute) {
  return await getAllPages(absolute).then(pages => {
    const pagesPromises = pages.data.map((page, i) => {
      const modulesPromises = page.modules.map(async (m, k) => {
        return await getModuleById(m.id).then(moduleResplonse => {
          return moduleResplonse.data
        })
      })

      return Promise.all(modulesPromises).then(modules => {
        page.modules = modules
        return page
      })
    })

    return Promise.all(pagesPromises).then(pages => {
      return pages
    })
  })
}

async function getPageByUriWithData(uri, absolute) {
  return await getPageByUri(uri, absolute).then(page => {
    if (!page || !page.data || !page.data.modules) return null

    const modulesPromises = page.data.modules.map(async (m, k) => {
      return await getModuleById(m.id, absolute).then(moduleResplonse => {
        return moduleResplonse.data
      })
    })

    return Promise.all(modulesPromises).then(modules => {
      page.data.modules = modules

      return page
    })
  })
}

async function getAllPagesFull(absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpoint
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // headers: authHeader()
  }
  //console.log("Call API endopont", `${ep}/full`)
  //return await fetch(`${ep}/full`, requestOptions).then(handleResponse);
  return await fetchRetry(`${ep}/full?action=build`, requestOptions, 3).then(
    handleResponse
  )
}

async function getAllPages(absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpoint
  const requestOptions = {
    method: "GET",
    // headers: authHeader()
  }
  //console.log("Call API endopont", `${ep}/entities`)
  return await fetch(`${ep}/entities?action=build`, requestOptions).then(
    handleResponse
  )
}

async function getPageById(id, absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpoint
  const requestOptions = {
    method: "GET",
    // headers: authHeader()
  }

  return await fetch(`${ep}/page/${id}.json`, requestOptions).then(
    handleResponse
  )
}

async function getPageByUri(uri, absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpoint
  const requestOptions = {
    method: "GET",
    // headers: authHeader() //
  }
  //console.log("Call API endopont", `${ep}/entity?uri=${uri}`)
  return await fetch(
    `${ep}/entity?uri=${uri}&action=build`,
    requestOptions
  ).then(handleResponse)
}

async function getSectionByType(type, country, absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpoint
  const requestOptions = {
    method: "GET",
    // headers: authHeader() //
  }
  /* console.log(
    "Call API endopont",
    `${ep}/section?type=${type}&country=${country}`
  ) */
  return await fetch(
    `${ep}/section?type=${type}&country=${country}`,
    requestOptions
  ).then(handleResponse)
}

async function getIndexesPages(country, absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpointIndexPages
  const requestOptions = {
    method: "GET",
    // headers: authHeader() //
  }
  console.log("Call API endopont", `${ep}?country=${country}`)
  return await fetch(`${ep}?country=${country}`, requestOptions).then(
    handleResponse
  )
}

async function getModuleById(id, absolute) {
  let ep = (absolute || local ? apihost : apihost) + endpoint
  const requestOptions = {
    method: "GET",
    // headers: authHeader()
  }
  //console.log("Call API endopont", `${ep}/module?id=${id}`)
  return await fetchRetry(
    `${ep}/module?id=${id}&action=build`,
    requestOptions,
    3
  ).then(handleResponse)
}

function fetchRetry(url, options, n) {
  return fetch(url, options).catch(function (error) {
    console.error(`Fetch fail on ${url}`)
    console.error(`Retry remaining ${n}...`)
    if (n === 1) throw error
    return fetchRetry(url, options, n - 1)
  })
}

function handleResponse(response) {
  return response.text().then(text => {
    try {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        const error = data || response.statusText
        return Promise.reject(error)
      }
      return data
    } catch (e) {
      console.error("Snake json parse error", response.url)
      return Promise.reject(e)
    }
  })
}

// Live API requests

async function search(country, searchText, page) {
  let ep = (local ? apihost : "") + endpointSearch
  return await fetch(`${ep}?c=${country}&q=${searchText}&p=${page}`, {
    method: "GET",
  }).then(handleResponse)
}

async function formSubmit(data) {
  return await fetch(endpointContact, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
}

async function getOfferByCode(code, version) {
  let ep = (local ? apihost : apihost) + endpointOffer
  return await fetch(`${ep}?code=${code}&version=${version}`, {
    method: "GET",
  }).then(handleResponse)
}

module.exports.snake = {
  getAllPages,
  getAllPagesFull,
  getPageById,
  getPageByUri,
  getModuleById,
  getAllPagesWithData,
  getPageByUriWithData,
  search,
  formSubmit,
  getOfferByCode,
  getSectionByType,
  getIndexesPages,
  getPagesList,
  getPageContents,
}
